import React from "react";
import { PageProps } from "gatsby";
import { Page } from "@/components/Page";
import { EmailConfirmation } from "@/features/Authentication/EmailConfirmation";

interface EmailConfirmationPageLocationState {
  email?: string;
  password?: string;
}

export const EmailConfirmationPageWrapper = ({
  location: { state },
}: PageProps<
  unknown,
  unknown,
  EmailConfirmationPageLocationState | undefined
>) => {
  const email = state?.email;
  const password = state?.password;

  return (
    <Page variation="presession">
      <EmailConfirmation email={email} password={password} />
    </Page>
  );
};

export default EmailConfirmationPageWrapper;
