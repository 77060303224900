/* eslint-disable max-statements */
import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { Auth } from "@aws-amplify/auth";
import { showToast } from "@jobber/components/Toast";
import { useDisplayError } from "@/hooks/useDisplayError";
import { ErrorMessages } from "@/utils/cognito/ErrorMessages";
import { EmailConfirmation } from "./EmailConfirmation";

interface EmailConfirmationLoaderProps {
  email?: string;
  password?: string;
}

export const EmailConfirmationLoader = ({
  email = "",
  password,
}: EmailConfirmationLoaderProps) => {
  const [isLoadingConfirmation, setIsLoadingConfirmation] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const { displayError } = useDisplayError();

  useEffect(() => {
    if (!email) navigate("/login");
  }, [email]);

  if (!email) return null;

  return (
    <EmailConfirmation
      email={email}
      invalid={invalid}
      isLoadingConfirmation={isLoadingConfirmation}
      onCodeConfirmationAttempt={handleCodeConfirmationAttempt}
      onSendCodeRequest={handleSendCodeRequest}
    />
  );

  async function handleSendCodeRequest() {
    try {
      await Auth.resendSignUp(email);
      showToast({
        message: `Verification code sent`,
        variation: "success",
      });
    } catch (error: any) {
      const message = error?.message;
      switch (message) {
        case ErrorMessages.USER_IS_ALREADY_CONFIRMED: {
          navigate("/login", { state: { email } });
          showToast({
            message: `Email confirmed`,
            variation: "success",
          });
          break;
        }
        default: {
          displayError([
            `Unable to send verification code to '${email}', please try again or contact support`,
          ]);
        }
      }
    }
  }

  async function handleCodeConfirmationAttempt(code: string) {
    setIsLoadingConfirmation(true);
    const firstLogIn = true;
    try {
      await Auth.confirmSignUp(email, code);
      showToast({
        message: `Email confirmed`,
        variation: "success",
      });
      try {
        await Auth.signIn(email, password);
        navigate("/", { state: { firstLogIn } });
      } catch {
        navigate("/login", { state: { email } });
      }
    } catch (error: any) {
      const message = error?.message;
      switch (message) {
        case ErrorMessages.USER_CANNOT_BE_CONFIRMED_IS_ALREADY_CONFIRMED: {
          navigate("/login", { state: { email } });
          showToast({
            message: `Email confirmed`,
            variation: "success",
          });
          break;
        }
        default: {
          setInvalid(true);
        }
      }
    } finally {
      setIsLoadingConfirmation(false);
    }
  }
};
