import React, { useState } from "react";
import { InputText } from "@jobber/components/InputText";
import { Emphasis } from "@jobber/components/Emphasis";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import { Divider } from "@jobber/components/Divider";
import { Text } from "@jobber/components/Text";
import { Form } from "@jobber/components/Form";
import { InputValidation } from "@jobber/components/InputValidation";
import * as styles from "@/features/Authentication/PresessionCommon/PresessionCommon.module.css";
import * as linkStyles from "@/components/Link/Link.module.css";
import { Banner } from "@/components/Banner";
import { JobberDevCenterLabel } from "../components/JobberDevCenterLabel";

interface EmailConfirmationProps {
  isLoadingConfirmation: boolean;
  invalid: boolean;
  onSendCodeRequest(): void;
  onCodeConfirmationAttempt(code: string): void;
  email?: string;
}

export const EmailConfirmation = ({
  isLoadingConfirmation,
  invalid,
  onSendCodeRequest,
  onCodeConfirmationAttempt,
  email,
}: EmailConfirmationProps) => {
  const [inputs, setInputs] = useState({
    code: "",
  });

  return (
    <div className={styles.common_wrapper}>
      <Banner />
      <JobberDevCenterLabel />
      <Form onSubmit={handleCodeConfirmationAttempt}>
        <Content>
          <Heading level={2}>Verify your email</Heading>
          <Text>
            Use the verification code sent to{" "}
            <Emphasis variation="bold">{email}</Emphasis> to confirm your email.
          </Text>
          <div>
            <div className={styles.validation_message}>
              <InputText
                placeholder="Verification code"
                size="large"
                invalid={invalid}
                validations={{
                  required: {
                    value: true,
                    message: "Please provide your verification code",
                  },
                }}
                onChange={handleInputChange({ input: "code" })}
              />
              {invalid && (
                <InputValidation message="Code does not match. Please check your email and try again." />
              )}
            </div>
          </div>
          <Button
            label="Continue"
            size="large"
            fullWidth
            submit
            loading={isLoadingConfirmation}
          />
          <Divider />
          <a
            style={{ cursor: "pointer" }}
            className={linkStyles.link}
            onClick={handleCodeSendRequest}
          >
            Resend verification code
          </a>
        </Content>
      </Form>
    </div>
  );

  function handleInputChange({ input }: { input: "email" | "code" }) {
    return (value: string) =>
      setInputs(current => ({ ...current, [input]: value }));
  }

  function handleCodeSendRequest() {
    onSendCodeRequest();
  }

  function handleCodeConfirmationAttempt() {
    const { code } = inputs;
    onCodeConfirmationAttempt(code);
  }
};
